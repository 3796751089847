<template>
  <div class="review-card" :class="{ 'review-card--full': showMore }">
    <img
      :src="useStrapiMediaUrl(content?.photo?.data?.attributes?.url)"
      loading="lazy"
    />
    <h3 class="text-16 text-bold">{{ content.name }}</h3>
    <span class="review-card__company">{{ content.company }}</span>
    <p class="text-12">
      {{ content.text }}
    </p>
    <span class="review-card__readmore" @click="handleClickShowMore(id)">
      {{ showMore ? "Скрыть" : "Читать весь отзыв" }}
    </span>
  </div>
</template>
<script setup lang="ts">
import type { Review } from "~/types/strapiContent"

const props = defineProps<{
  id: Number
  content: Review
}>()
const openId = ref()
const showMore = ref()
function handleClickShowMore(id) {
  openId.value = id
  if (openId.value === id) showMore.value = !showMore.value
}
</script>
<style lang="scss">
.review-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 310px;
  max-width: 310px;
  height: 340px;
  color: $white;
  background-color: $color-purple;
  border-radius: 10px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &--full {
    height: max-content;
    p {
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
    }
  }
  &--full &__readmore {
    margin: 10px 0 0 0;
  }
  &__company {
    margin: 0 0 10px 0;
    font-size: 16px;
    line-height: 20px;
  }
  &__readmore {
    margin: auto 0 0 0;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    margin: 0 0 16px 0;
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
  }
  h3 {
    margin: 0 0 10px 0;
  }
  span {
    display: inline-block;
  }
}
</style>
