<template>
  <section
    v-if="content.length"
    ref="reviewsRef"
    class="section section--reviews"
  >
    <div class="section__wrapper">
      <section id="review" class="review-gsap">
        <h2 class="text-44 text-bold title-mb">Отзывы</h2>
        <ul ref="reviews" class="reviews-list">
          <ReviewsCard
            v-for="review in content"
            :id="review.id"
            :key="review.id"
            :content="review?.attributes"
          />
        </ul>
        <slot></slot>
      </section>
    </div>
  </section>
</template>
<script setup lang="ts">
import { getReviews } from "@/backend/composables/base"
// const { $gsap, $ScrollTrigger } = useNuxtApp()
// $gsap.registerPlugin($ScrollTrigger)

const reviewsData = await getReviews()
const content = computed(() => {
  return reviewsData
})
// const slidersPerPage = ref()
// const countPaddingByWindowWidth = ref()
// const windowWidth = ref()
const reviews = ref()

// onBeforeMount(() => {
//   windowWidth.value = window.innerWidth
//   window.addEventListener("resize", handleSizeWindow)
//   handleSizeWindow()
// })
// function handleSizeWindow() {
//   windowWidth.value = window.innerWidth
//   if (windowWidth.value >= 1081) {
//     slidersPerPage.value = 4
//     countPaddingByWindowWidth.value = 140 - 20
//   }
//   if (windowWidth.value <= 1080) {
//     slidersPerPage.value = 2
//     countPaddingByWindowWidth.value = 72 - 20
//   }
//   if (windowWidth.value <= 580) {
//     slidersPerPage.value = 1
//     countPaddingByWindowWidth.value = 32 - 20
//   }
// }
// const reviewsRef = ref()
// let ctx
// onMounted(() => {
//   setTimeout(() => {
//     const blockWidth = 1300
//     const items = $gsap.utils.toArray(".review-card")
//     let totalShift =
//       310 * items.length +
//       (20 * items.length + countPaddingByWindowWidth.value) -
//       windowWidth.value
//     if (windowWidth.value > 1440)
//       totalShift += (windowWidth.value - blockWidth) / 2

//     ctx = $gsap.context(() => {
//       const trig = document.querySelector(".review-gsap")
//       $gsap.to(items, {
//         x: `-${totalShift}`,
//         scrollTrigger: {
//           trigger: trig,
//           scrub: 1,
//           pin: true,
//           end: `+=${totalShift}`,
//         },
//       })
//     }, reviewsRef.value)
//   }, 1200)
// })
// onUnmounted(() => {
//   ctx.revert() // <- Easy Cleanup!
// })
</script>
<style lang="scss" scoped>
.section {
  &--reviews {
    position: relative;
    padding: 175px 70px 70px 70px;
    margin-top: -35px;
    background-image: url("@/public/img/bg_reviews.png");
    background-repeat: no-repeat;
    background-position: top -20px left -35px;
    background-size: 40%;
    border-radius: 0 0 0 0;
    overflow: hidden;
    @include md {
      padding: 105px 36px 70px 36px;
      margin: -15px 0 0 0;
      background-position: top -15px left -25px;
      background-size: 60%;
    }
    @include sm {
      padding: 80px 16px 60px 16px;
      margin: 0 0 0 0;
      background-position: top 0px left -10px;
      background-size: 85%;
    }
  }
}
.reviews {
  &__header {
    display: flex;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
    }
  }
}
.reviews-list {
  height: 430px;
  display: flex;
  gap: 20px;
}
</style>
